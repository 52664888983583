* {
  font-family: sans-serif;
   margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.ant-btn {
  box-shadow: none !important;
}
.top-menu-container {
  padding: 0.4% 5%;
  color:#fff;
   background-color: #0C6F8D;
   /* box-shadow: 1px 1px 5px #599cd3; */
    display: flex;
  align-items: right;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  min-height: 70px;
}

.top-menu {
  /* padding: 15px 0px;  */
  font-size: 16px;
   display: inline-flex;
  flex-flow: row nowrap;
  flex: 1;
}

.top-menu li{
  list-style: none;
  display: inline;
}

.top-menu li a{
  list-style: none;
  display: inline;
  cursor: pointer;
  color: #ffffff;
}
a{
  color: #fff;
}

/* .outlet-style{
  margin-top: 5%;
  height: 91.4vh;
  overflow-y: auto;
} */
.side-nav-style {  
 background-color: #0c6f8d;
z-index: 1;
min-height: 100vh;
/* padding: 0 6px;      */
}
/* .top-menu li:hover{
  color:rgb(64, 159, 214);
} */
button{
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  margin:5px;
}
button:hover {
  opacity: 0.9;
}
.inp{
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color:#0c6f8d;
  width:70%;              
}
.small-screen-menu {
  background-color: #fffcfc;
  padding: 1rem 0;
}
.small-screen-menu li{
  list-style: none;
  padding:0.5rem 1rem;
  background-color: #282c3427;
  color: #f1f1f1;
  margin: 3px 0;
  border-radius: 4px;
  font-size: 14px;
}
.small-screen-menu li:hover {
  background-color: rgba(136, 136, 136, 0.164);
  cursor: pointer;
}
.active {
  font-weight: bold;
}
.get-stt {
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding:10px 40px;
}

.blue {
  background-color: #0c6f8d;
  color: rgb(241, 242, 243);
}
.orange {
  background-color: #f0a80cdc;
  color: #fffcfc;
}
.black {
  background-color: #0e0f0f;
  color:#fff;
}
.green {
  background-color: #089131;
  color:#fff;
}

.tbHead{
  background-color: #0c6f8d;
  padding:0.5rem 0.5rem;
  cursor: pointer;
  margin:1px 0;
  color: #ffffff;
}
.tbBody {
  background-color: #f3f3f3;
  padding:0.4rem 0.5rem;
  cursor: pointer;
  margin:1px 0;
  color: #131313;
}
.tbBody:hover {
   background-color: #f3f3f369;
}
.custms-chat {
  background-color: #b8b5b51a; 
  padding: 0.5rem 0;
  cursor: pointer;
  /* border-bottom: 1px solid #08900040; */
  margin: 2px 0;
  padding: 0.5rem 1rem;
               
}
.custms-chat:hover {
  background-color: #adabab34;
}
.chat-title {
  border-bottom: 1px solid rgba(155, 155, 155, 0.815);
  background-color: #ffffff;
  padding:0.4rem;
}

.chat-container {
 color:rgb(167, 167, 167);

}
.chat-bg {
  padding:0.5rem 1rem;
  height: 57vh;
  overflow-y: auto;
}
.chat-gy {
   background-color: #b8b5b51a;
   padding:1rem;
    border-radius:0 0 10px 10px;
}
.chat-type {
  background-color: #e2e4e7;
  border-radius: 30px;
  padding:1rem 0.5rem;
 margin-top: 1rem;
 width: 100%;
 
}
.chat-type input{
  background-color: transparent;
  color: #000;
  border-radius: 30px;
  outline: none;
  border: none;
}
.msg-l {
  background-color: #0c6f8d;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0 10px 10px 10px;
  width: fit-content;
  text-align: left;
  margin:0.5rem 0;
}
.msg-r {
  background-color: #e2e4e7;
  color: rgb(39, 39, 39);
  float: right;
  padding: 0.5rem 1rem;
  border-radius: 10px 0 10px 10px;
  width: fit-content;
  margin:0.5rem 0;
}

.footer {
  background-color: #020202dc;
  text-align: left;
  line-height: 2;
}

.footer .ant-typography {
  color:#f1f1f1;
}
.footer .quick-links {
  color:#f1f1f1 ;
}
